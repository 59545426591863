// JsFromRoutes CacheKey b1a67fa84efd970ffd08a4a865e93bcd
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@consumer/services/api'

export default {
  moreInfo: /* #__PURE__ */ definePathHelper('get', '/support/more-info/:gift_id/:security_token'),
  submitMoreInfo: /* #__PURE__ */ definePathHelper('post', '/support/more-info/:gift_id/:security_token'),
  index: /* #__PURE__ */ definePathHelper('get', '/support'),
  search: /* #__PURE__ */ definePathHelper('post', '/support/search'),
  contactUs: /* #__PURE__ */ definePathHelper('get', '/support/contact-us'),
  category: /* #__PURE__ */ definePathHelper('get', '/support/:category'),
  article: /* #__PURE__ */ definePathHelper('get', '/support/:category/articles/:key'),
}
