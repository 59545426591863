<script setup lang="ts">
import { useProgress } from '@corp/services/progress'

// The percentage of progress for the navigation bar.
const { progressPercentage, inProgress, transitionDuration } = useProgress()

// How long it takes to animate the progress bar.
const transitionDurationStr = `${transitionDuration}ms`
</script>

<template>
  <div class="page-progress-bar fixed top-0 left-0 right-0 z-navigation-progress">
    <div class="bar transform" :class="{ 'in-progress': inProgress }"/>
  </div>
</template>

<style scoped lang="scss">
$color: theme("colors.gold.400");

.bar {
  --un-translate-x: calc(v-bind("progressPercentage") * 100% - 100%);
  --tw-translate-x: calc(v-bind("progressPercentage") * 100% - 100%);

  background-color: $color;
  box-shadow: 0 0 10px $color, 0 0 5px $color;
  height: 2px;

  &.in-progress {
    transition: transform ease v-bind("transitionDurationStr");
  }
}
</style>
