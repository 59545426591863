<script lang="ts" setup>
import { support } from '@consumer/api'
import { getYear } from 'date-fns'
const year = getYear(new Date())

const corporateBase = import.meta.env.CORPORATE_LANDING_URL || '/corp'
</script>

<template>
  <footer id="footer" class="pt-25px lg:pt-60px bg-footer text-white">
    <div class="flex flex-wrap justify-left px-16px container max-w-full lg:max-w-916px">
      <div class="mb-45px px-10px lg:px-0 mw-25">
        <h2>Help</h2>
        <ul>
          <li><GLink :href="support.index.path()">Help Center</GLink></li>
          <li>
            <HowItWorksLink>How Giftly works</HowItWorksLink>
          </li>
          <li><GLink :href="support.category.path({ category: 'learnmore' })">FAQ</GLink></li>
          <li class="ftr-email"><a class="ftr-act-link" href="mailto:support@giftly.com">support@giftly.com</a></li>
        </ul>
      </div>
      <div class="mb-45px px-10px lg:px-0 mw-25">
        <h2>Corporate</h2>
        <ul>
          <li><a :href="corporateBase">Why Giftly</a></li>
          <li><a :href="`${corporateBase}/signup`">Sign Up</a></li>
          <li><a :href="`${corporateBase}/login`">Log In</a></li>
          <li class="ftr-email"><a class="ftr-act-link" href="mailto:corporate@giftly.com">corporate@giftly.com</a></li>
        </ul>
      </div>
      <div class="mb-45px px-10px lg:px-0 mw-25">
        <h2>Merchants</h2>
        <ul>
          <li><a href="/merchants" rel="nofollow">Customize listing</a></li>
          <li><GLink href="/support/merchant_search">Remove listing</GLink></li>
          <li><a href="https://www.giftlymerchantadvertising.com/" rel="nofollow">Settlement Notice</a></li>
          <li><GLink href="/support/merchant">FAQ</GLink></li>
          <li class="ftr-email"><a class="ftr-act-link" href="mailto:merchants@giftly.com">merchants@giftly.com</a></li>
        </ul>
      </div>
      <div class="mb-45px px-10px lg:px-0 mw-10">
        <h2>About</h2>
        <ul>
          <li><GLink href="/about">Team</GLink></li>
          <li><GLink href="/press">Press</GLink></li>
          <li><a href="https://blog.giftly.com">Blog</a></li>
        </ul>
      </div>
      <div class="mb-45px px-10px lg:px-0 mw-15">
        <h2>Legal</h2>
        <ul>
          <li><GLink href="/legal">Terms</GLink></li>
          <li><GLink href="/privacy">Privacy</GLink></li>
          <li><GLink href="/giftagreement">Gift Agreement</GLink></li>
        </ul>
      </div>
      <div class="mb-45px px-10px lg:px-0 mw-25 mw-xs-100">
        <h2>Business categories</h2>
        <ul class="grid grid-cols-2 md:grid-cols-1">
          <li><a href="/restaurant-gift-cards">Restaurants</a></li>
          <li><a href="/travel-gift-cards">Travel</a></li>
          <li><a href="/shopping-gift-cards">Shopping</a></li>
          <li><a href="/activity-gift-cards">Activities</a></li>
          <li><a href="/spa-beauty-gift-cards">Spa &amp; Beauty</a></li>
          <li><a href="/outdoor-gift-cards">Outdoors</a></li>
          <li><a href="/going-out-gift-cards">Going Out</a></li>
          <li><a href="/brands/top-brands-gift-cards">Nationwide Brands</a></li>
          <li class="ftr-act-link"><a href="/categories">All Business Categories</a></li>
        </ul>
      </div>
      <div class="mb-45px px-10px lg:px-0 mw-25 mw-xs-100">
        <h2>Business locations</h2>
        <ul class="grid grid-cols-2">
          <li><a href="/atlanta-ga-gift-cards">Atlanta</a></li>
          <li><a href="/austin-tx-gift-cards">Austin</a></li>
          <li><a href="/boston-ma-gift-cards">Boston</a></li>
          <li><a href="/chicago-il-gift-cards">Chicago</a></li>
          <li><a href="/dallas-tx-gift-cards">Dallas</a></li>
          <li><a href="/denver-co-gift-cards">Denver</a></li>
          <li><a href="/detroit-mi-gift-cards">Detroit</a></li>
          <li><a href="/houston-tx-gift-cards">Houston</a></li>
          <li><a href="/las-vegas-nv-gift-cards">Las Vegas</a></li>
          <li><a href="/los-angeles-ca-gift-cards">Los Angeles</a></li>
          <li><a href="/miami-fl-gift-cards">Miami</a></li>
          <li><a href="/minneapolis-mn-gift-cards">Minneapolis</a></li>
          <li><a href="/new-york-ny-gift-cards">New York</a></li>
          <li><a href="/oakland-ca-gift-cards">Oakland</a></li>
          <li><a href="/philadelphia-pa-gift-cards">Philadelphia</a></li>
          <li><a href="/phoenix-az-gift-cards">Phoenix</a></li>
          <li><a href="/portland-or-gift-cards">Portland</a></li>
          <li><a href="/st-louis-mo-gift-cards">St. Louis</a></li>
          <li><a href="/san-diego-ca-gift-cards">San Diego</a></li>
          <li><a href="/san-francisco-ca-gift-cards">San Francisco</a></li>
          <li><a href="/san-jose-ca-gift-cards">San Jose</a></li>
          <li><a href="/seattle-wa-gift-cards">Seattle</a></li>
          <li><a href="/washington-dc-gift-cards">Wash, DC</a></li>
          <li class="ftr-act-link"><a href="/locations/states">Browse All</a></li>
        </ul>
      </div>
      <div class="mb-45px px-10px lg:px-0 mw-25 mw-xs-100">
        <h2>Restaurants</h2>
        <ul class="grid grid-cols-2">
          <li><a href="/seafood-restaurant-gift-cards">Seafood</a></li>
          <li><a href="/steakhouse-restaurant-gift-cards">Steakhouses</a></li>
          <li><a href="/italian-restaurant-gift-cards">Italian</a></li>
          <li><a href="/french-restaurant-gift-cards">French</a></li>
          <li><a href="/asian-fusion-restaurant-gift-cards">Asian Fusion</a></li>
          <li><a href="/pizza-restaurant-gift-cards">Pizza</a></li>
          <li><a href="/sushi-restaurant-gift-cards">Sushi</a></li>
          <li><a href="/burger-restaurant-gift-cards">Burgers</a></li>
          <li><a href="/mexican-restaurant-gift-cards">Mexican</a></li>
          <li><a href="/japanese-restaurant-gift-cards">Japanese</a></li>
          <li class="ftr-act-link"><a href="/categories">All Restaurant Types</a></li>
        </ul>
      </div>
      <div class="mb-45px px-10px lg:px-0 mw-25 mw-xs-100">
        <h2>Gift Ideas</h2>
        <ul class="grid grid-cols-1">
          <li><a href="/mp">Multi-Place Gift Ideas</a></li>
          <!-- <li><a href="/mp/home-depot-lowes-home-improvement-gift-card">Home Improvement</a></li>
          <li><a href="/mp/mcdonalds-burger-lover">Fast Food Favorites</a></li>
          <li><a href="/mp/starbucks-coffee-card">Coffee Coast-to-Coast</a></li>
          <li><a href="/mp/steakhouse-gift-card">Steak Savvy</a></li>
          <li><a href="/mp/dominos-pizza-gift">Pizza Pass</a></li>
          <li><a href="/mp/allegiant-expedia-gift-card">The Jetsetter Card</a></li>
          <li><a href="/mp/lululemon-style-gift-card">Fit &amp; Fancy</a></li>
          <li><a href="/mp/food-delivery-gift-card">Universal Food Delivery</a></li>
          <li><a href="/mp/antropologie-madewell-retail-gift">Retail Therapy Card</a></li>
          <li><a href="/mp/dicks-sporting-outdoors-gift">Great Outdoors Card</a></li>
          <li><a href="/mp/walmart-costco-shopping-gift-card">Shop Smart</a></li> -->
        </ul>
      </div>
      <div class="mb-45px w-full px-10px md:px-0 text-center">
        <p class="text-grey-300 text-12px leading-25px mb-15px">
          The Giftly Prepaid Gift is issued by Sunrise Banks N.A., St. Paul, MN 55103, Member FDIC.
          * Standard data rates, fees, and charges may apply to send by text.
        </p>
        <p class="text-grey-300 text-12px leading-25px mb-45px">Copyright © {{ year }} Giftly. All rights reserved.</p>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
#footer {
  h2 {
    @apply text-16px text-white font-light leading-22.4px mb-15px capitalize;
  }

  ul {
    li {
      @apply mb-5px leading-25.6px;

      a {
        @apply text-12px text-grey-300 no-underline hover:text-primary-base;

        transition: all 0.3s ease-in-out;
      }

      &.ftr-act-link {
        @apply mb-1.25;

        a {
          @apply text-primary-base;
        }
      }

      &.ftr-email {
        @apply pt-1.25 mb-1.25;

        a {
          @apply text-primary-base;
        }
      }
    }
  }

  .mw-10 {
    @media only screen and (max-width: 767px) {
      flex: 0 0 13.33% !important;
      max-width: 13.33% !important;
    }

    @media only screen and (max-width: 576px) {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }

    flex: 0 0 10% !important;
    max-width: 10% !important;
  }

  .mw-15 {
    @media only screen and (max-width: 767px) {
      flex: 0 0 20% !important;
      max-width: 20% !important;
    }

    @media only screen and (max-width: 576px) {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    flex: 0 0 15% !important;
    max-width: 15% !important;
  }

  .mw-25 {
    @media only screen and (max-width: 767px) {
      flex: 0 0 33.33% !important;
      max-width: 33.33% !important;
    }

    @media only screen and (max-width: 576px) {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }

    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .mw-xs-100 {
    @media only screen and (max-width: 576px) {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    ul {
      li {
        @media only screen and (max-width: 576px) {
          display: inline;
          float: left;
          width: 50%;
        }
      }
    }
  }
}
</style>
